import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { priceTotalAtom } from '../../../../recoil/atoms/builder'
import { pricePreTotalAtom } from '../../../../recoil/atoms/builderPre'
import { initBuilderAtom } from '../../../../recoil/atoms/intializeBuilder'
import { customBoardAtom } from '../../../../recoil/atoms/builder'

import './sumBuilderStyles.css'
import { Button } from 'antd'


// // my filter out fuction customized!
const filterOutObject = (obj, keyToRemove) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (key !== keyToRemove) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

// customized functions to open links in tabs for Buy all
// Custom builds
const openLinksInNewTabsCustom = () => {
  const storedValue = JSON.parse(localStorage.getItem('persist-custom'));
  const filterStoredValue = filterOutObject(storedValue, 'learnMoreState');
  const customValues = Object.values(filterStoredValue)
  const mappedCustom = customValues.map(value => {
    // Do something with each value
    return value.buyLink;
  });
  mappedCustom.forEach(href => {
    window.open(href, '_blank');
  });
};

// Prebuilts

const openLinksInNewTabsPre = () => {
  const storedValue = JSON.parse(localStorage.getItem('persist-pre'));
  const filterStoredValue = filterOutObject(storedValue, 'learnMoreState');
  const preValues = Object.values(filterStoredValue)
  const mappedPre = preValues.map(value => {
    // Do something with each value
    return value.buyLink;
  });
  mappedPre.forEach(href => {
    window.open(href, '_blank');
  });
};

export default function SumBuilder() {

  const readBuilderType = useRecoilValue(initBuilderAtom)
  const readTotal = useRecoilValue(priceTotalAtom)
  const readPreTotal = useRecoilValue(pricePreTotalAtom)

  return (
    <div className="sum-component">
      {readBuilderType === "custom" ? (
        <>
          <p>{`Total: ${readTotal.toFixed(2)}`}</p>
          {/* <Button
            type="primary"
            onClick={() => {
              openLinksInNewTabsCustom();
            }}
          >
            Buy all
          </Button> */}
        </>
      ) : readBuilderType === "pre" ? (
        <>
          <p>{`Total: ${readPreTotal.toFixed(2)}`}</p>
          {/* <Button
            type="primary"
            onClick={() => {
              openLinksInNewTabsPre();
            }}
          >
            Buy all
          </Button> */}
        </>
      ) : null}
    </div>
  );
}
