import React, { useEffect, useState } from "react";
import "./AddComponentStyles.css";
import {
  ArrowLeftOutlined,
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Descriptions,
  Image,
  Input,
  Modal,
  notification,
  Rate,
  Result,
  Slider,
  Spin,
  Switch,
  Collapse,
} from "antd";
import AliIcon from "../../../../assets/icons/AliIcon";
import ModalBuilderCard from "../modalinfocard/ModalBuilderCard";

import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import {
  preCableAtom,
  preDeskmatsAtom,
  preKeycapsAtom,
  preLubricationAtom,
  preOringsAtom,
  prePreBuiltAtom,
  preStabilizersAtom,
  preSwitchFilmsAtom,
  preWristRestsAtom,
} from "../../../../recoil/atoms/builderPre";
import {
  customBoardAtom,
  customCableAtom,
  customDeskmatsAtom,
  customKeycapPullerAtom,
  customKeycapsAtom,
  customLubricationAtom,
  customOringsAtom,
  customStabilizersAtom,
  customSwitchesAtom,
  customSwitchFilmsAtom,
  customSwitchHolderAtom,
  customSwitchOpenerAtom,
  customSwitchTesterAtom,
  customWristRestsAtom,
  learnMoreAtom,
} from "../../../../recoil/atoms/builder";
import {
  loaderTestData,
  testDataAtom,
} from "../../../../recoil/atoms/testDataBuilder";
import {
  learnMoreInfo,
  modalDetailPage,
} from "../../../../recoil/atoms/modalContent";

import { initBuilderAtom } from "../../../../recoil/atoms/intializeBuilder";

export default function AddComponent(props) {
  const builderTypeAtomRead = useRecoilValue(initBuilderAtom);

  // useEffect(() => {
  //   console.log(builderTypeAtomRead)
  // }, [builderTypeAtomRead])

  const modalInfo = useRecoilValue(testDataAtom);
  const [modalVisibility, setModalVisibility] = useState(false);

  // loader when fetching data
  const readProductLoader = useRecoilValue(loaderTestData);

  function showModal() {
    setModalVisibility(true);
    console.log("modal trigger worked!");
  }

  const handleCancel = () => {
    setModalVisibility(false);
    resetModalDetailInfo();
  };

  // reset pre
  const resetPrePreBuilt = useResetRecoilState(prePreBuiltAtom);
  const resetPreKeycaps = useResetRecoilState(preKeycapsAtom);
  const resetPreLubrication = useResetRecoilState(preLubricationAtom);
  const resetPreStabilizers = useResetRecoilState(preStabilizersAtom);
  const resetPreOrings = useResetRecoilState(preOringsAtom);
  const resetPreSwitchFilms = useResetRecoilState(preSwitchFilmsAtom);
  const resetPreCable = useResetRecoilState(preCableAtom);
  const resetPreDeskmats = useResetRecoilState(preDeskmatsAtom);
  const resetPreWristRests = useResetRecoilState(preWristRestsAtom);

  // reset custom
  const resetCustomBoard = useResetRecoilState(customBoardAtom);
  const resetCustomSwitches = useResetRecoilState(customSwitchesAtom);
  const resetCustomKeycaps = useResetRecoilState(customKeycapsAtom);
  const resetCustomLubrication = useResetRecoilState(customLubricationAtom);
  const resetCustomStabilizers = useResetRecoilState(customStabilizersAtom);
  const resetCustomOrings = useResetRecoilState(customOringsAtom);
  const resetCustomSwitchFilms = useResetRecoilState(customSwitchFilmsAtom);
  const resetCustomCable = useResetRecoilState(customCableAtom);
  const resetCustomSwitchTester = useResetRecoilState(customSwitchTesterAtom);
  const resetCustomKeycapPuller = useResetRecoilState(customKeycapPullerAtom);
  const resetCustomSwitchOpener = useResetRecoilState(customSwitchOpenerAtom);
  const resetCustomSwitchHolder = useResetRecoilState(customSwitchHolderAtom);
  const resetCustomDeskmats = useResetRecoilState(customDeskmatsAtom);
  const resetCustomWristRests = useResetRecoilState(customWristRestsAtom);

  const notificationRemoved = (type) => {
    notification[type]({
      message: "Removed!",
      description: `${props.componentType} removed from your build`,
    });
  };

  // table conditional styling

  const tdFilledStyle = {
    backgroundColor: "white",
    color: "#181818",
  };

  const tdFilledFirst = {
    borderRadius: "20px 0 0 20px",
    paddingLeft: "24px",
    maxWidth: "100px",
  };

  const tdFilledLast = {
    borderRadius: "0 20px 20px 0",
  };

  // logic for displaying a single page inside the modal

  const modalDetailInfo = useRecoilValue(modalDetailPage);
  const modalDetailInfoComponent = useRecoilValue(learnMoreInfo);
  const resetModalDetailInfo = useResetRecoilState(modalDetailPage);

  // dynamic main image for component

  const mainImageStyle = {
    backgroundImage: `url(${modalDetailInfoComponent.info.img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  };

  // add button logic for learn more items

  const notificationSuccess = (type) => {
    notification[type]({
      message: "Success!",
      description: `${getLocalInfo.category} added to your build`,
    });
  };

  // pre
  const [prePreBuilt, setPrePreBuilt] = useRecoilState(prePreBuiltAtom);
  const [preKeycaps, setPreKeycaps] = useRecoilState(preKeycapsAtom);
  const [preLubrication, setPreLubrication] =
    useRecoilState(preLubricationAtom);
  const [preStabilizers, setPreStabilizers] =
    useRecoilState(preStabilizersAtom);
  const [preOrings, setPreOrings] = useRecoilState(preOringsAtom);
  const [preSwitchFilms, setPreSwitchFilms] =
    useRecoilState(preSwitchFilmsAtom);
  const [preCable, setPreCable] = useRecoilState(preCableAtom);
  const [preDeskmats, setPreDeskmats] = useRecoilState(preDeskmatsAtom);
  const [preWristRests, setPreWristRests] = useRecoilState(preWristRestsAtom);

  //custom
  const [customBoard, setCustomBoard] = useRecoilState(customBoardAtom);
  const [customSwitches, setCustomSwitches] =
    useRecoilState(customSwitchesAtom);
  const [customKeycaps, setCustomKeycaps] = useRecoilState(customKeycapsAtom);
  const [customLubrication, setCustomLubrication] = useRecoilState(
    customLubricationAtom
  );
  const [customStabilizers, setCustomStabilizers] = useRecoilState(
    customStabilizersAtom
  );
  const [customOrings, setCustomOrings] = useRecoilState(customOringsAtom);
  const [customSwitchFilms, setCustomSwitchFilms] = useRecoilState(
    customSwitchFilmsAtom
  );
  const [customCable, setCustomCable] = useRecoilState(customCableAtom);
  const [customSwitchTester, setCustomSwitchTester] = useRecoilState(
    customSwitchTesterAtom
  );
  const [customKeycapPuller, setCustomKeycapPuller] = useRecoilState(
    customKeycapPullerAtom
  );
  const [customSwitchOpener, setCustomSwitchOpener] = useRecoilState(
    customSwitchOpenerAtom
  );
  const [customSwitchHolder, setCustomSwitchHolder] = useRecoilState(
    customSwitchHolderAtom
  );
  const [customDeskmats, setCustomDeskmats] =
    useRecoilState(customDeskmatsAtom);
  const [customWristRests, setCustomWristRests] =
    useRecoilState(customWristRestsAtom);

  const getLocalInfo = useRecoilValue(learnMoreAtom);

  function addToBuilder() {
    const productObject = getLocalInfo;
    // console.log("It works: ", JSON.stringify(productObject));
    if (builderTypeAtomRead === "pre") {
      if (productObject.category === "Pre-built") {
        setPrePreBuilt(productObject);
      } else if (productObject.category === "Keycaps") {
        setPreKeycaps(productObject);
      } else if (productObject.category === "Lubrication") {
        setPreLubrication(productObject);
      } else if (productObject.category === "Stabilizers") {
        setPreStabilizers(productObject);
      } else if (productObject.category === "O-Rings") {
        setPreOrings(productObject);
      } else if (productObject.category === "Switch Films") {
        setPreSwitchFilms(productObject);
      } else if (productObject.category === "Cable") {
        setPreCable(productObject);
      } else if (productObject.category === "Deskmats") {
        setPreDeskmats(productObject);
      } else if (productObject.category === "Wrist rests") {
        setPreWristRests(productObject);
      }
    } else if (builderTypeAtomRead === "custom") {
      if (productObject.category === "Board") {
        setCustomBoard(productObject);
      } else if (productObject.category === "Switch") {
        setCustomSwitches(productObject);
      } else if (productObject.category === "Keycaps") {
        setCustomKeycaps(productObject);
      } else if (productObject.category === "Lubrication") {
        setCustomLubrication(productObject);
      } else if (productObject.category === "Stabilizers") {
        setCustomStabilizers(productObject);
      } else if (productObject.category === "O-Rings") {
        setCustomOrings(productObject);
      } else if (productObject.category === "Switch Films") {
        setCustomSwitchFilms(productObject);
      } else if (productObject.category === "Cable") {
        setCustomCable(productObject);
      } else if (productObject.category === "Switch Tester") {
        setCustomSwitchTester(productObject);
      } else if (productObject.category === "Keycap Pullers") {
        setCustomKeycapPuller(productObject);
      } else if (productObject.category === "Switch Opener") {
        setCustomSwitchOpener(productObject);
      } else if (productObject.category === "Switch Holder") {
        setCustomSwitchHolder(productObject);
      } else if (productObject.category === "Deskmats") {
        setCustomDeskmats(productObject);
      } else if (productObject.category === "Wrist rests") {
        setCustomWristRests(productObject);
      }
    }
    notificationSuccess("success");
    handleCancel();
  }

  // variable that stores the category to filter product out based on button selected

  const [filterComponent, setFilterComponent] = useState("");

  // 1 - General Filter Logic

  // RULES: removing redundant values
  const redundantValues = [null, undefined, 0, ""];

  // Pricing
  const [priceFilterValues, setPriceFilterValues] = useState([0, 150]);

  // Rating
  const fixedRatings = ["0.0", "20.0", "40.0", "60.0", "80.0", "100.0"];
  const [selectedRatings, setSelectedRatings] = useState([]);
  const handleFilterRating = (checkedValues) => {
    setSelectedRatings(checkedValues);
  };

  // pre-builts attributes

  // preSize
  const collectPreSizes = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Pre-built")
        .map((sizes) => sizes.product.attributes.prebuilt_size)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesPreSizes = Array.from([
    ...new Set(collectPreSizes.flat(Infinity)),
  ]);
  const [selectedPreSizes, setSelectedPreSizes] = useState([]);
  const handleFilterPreSize = (checkedValues) => {
    setSelectedPreSizes(checkedValues);
  };

  // preLed
  const collectPreLed = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Pre-built")
        .map((leds) => leds.product.attributes.prebuilt_led)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesPreLed = Array.from([...new Set(collectPreLed.flat(Infinity))]);
  const [selectedPreLed, setSelectedPreLed] = useState([]);
  const handleFilterPreLed = (checkedValues) => {
    setSelectedPreLed(checkedValues);
  };

  // preSwitchSupport
  const collectPreSwitchSupport = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Pre-built")
        .map(
          (switchSupport) =>
            switchSupport.product.attributes.prebuilt_switch_support
        )
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesPreSwitchSupport = Array.from([
    ...new Set(collectPreSwitchSupport.flat(Infinity)),
  ]);
  const [selectedPreSwitchSupport, setSelectedPreSwitchSupport] = useState([]);
  const handleFilterPreSwitchSupport = (checkedValues) => {
    setSelectedPreSwitchSupport(checkedValues);
  };

  // preBrand
  const collectPreBrand = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Pre-built")
        .map((brands) => brands.product.attributes.prebuilt_brand)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesPreBrand = Array.from([
    ...new Set(collectPreBrand.flat(Infinity)),
  ]);
  const [selectedPreBrand, setSelectedPreBrand] = useState([]);
  const handleFilterPreBrand = (checkedValues) => {
    setSelectedPreBrand(checkedValues);
  };

  // preColors
  const collectPreColors = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Pre-built")
        .map((colors) => colors.product.attributes.prebuilt_colors_available)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesPreColors = Array.from([
    ...new Set(collectPreColors.flat(Infinity)),
  ]);
  const [selectedPreColor, setSelectedPreColor] = useState([]);
  const handleFilterPreColor = (checkedValues) => {
    setSelectedPreColor(checkedValues);
  };

  const collectPreLayout = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Pre-built")
        .map((layout) => layout.product.attributes.prebuilt_layout)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesPreLayout = Array.from([
    ...new Set(collectPreLayout.flat(Infinity)),
  ]);
  const [selectedPreLayout, setSelectedPreLayout] = useState([]);
  const handleFilterPreLayout = (checkedValues) => {
    setSelectedPreLayout(checkedValues);
  };

  const collectPreWired = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Pre-built")
        .map((wired) => wired.product.attributes.prebuilt_wired)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesPreWired = Array.from([
    ...new Set(collectPreWired.flat(Infinity)),
  ]);
  const [selectedPreWired, setSelectedPreWired] = useState([]);
  const handleFilterPreWired = (checkedValues) => {
    setSelectedPreWired(checkedValues);
  };

  // Board attributes

  const collectSizes = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Board")
        .map((sizes) => sizes.product.attributes.board_size)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesSizes = Array.from([...new Set(collectSizes.flat(Infinity))]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const handleFilterSize = (checkedValues) => {
    setSelectedSizes(checkedValues);
  };

  const collectBoardBrand = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Board")
        .map((brands) => brands.product.attributes.board_brand)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesBoardBrand = Array.from([
    ...new Set(collectBoardBrand.flat(Infinity)),
  ]);
  const [selectedBoardBrand, setSelectedBoardBrand] = useState([]);
  const handleFilterBoardBrand = (checkedValues) => {
    setSelectedBoardBrand(checkedValues);
  };

  const collectBoardBluetooth = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Board")
        .map((bluetooths) => bluetooths.product.attributes.board_bluetooth)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesBoardBluetooth = Array.from([
    ...new Set(collectBoardBluetooth.flat(Infinity)),
  ]);
  const [selectedBoardBluetooth, setSelectedBoardBluetooth] = useState([]);
  const handleFilterBoardBluetooth = (checkedValues) => {
    setSelectedBoardBluetooth(checkedValues);
  };

  const collectBoardCableAttachment = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Board")
        .map((cables) => cables.product.attributes.board_cableAttachment)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesBoardCableAttachment = Array.from([
    ...new Set(collectBoardCableAttachment.flat(Infinity)),
  ]);
  const [selectedBoardCableAttachment, setSelectedBoardCableAttachment] =
    useState([]);
  const handleFilterBoardCableAttachment = (checkedValues) => {
    setSelectedBoardCableAttachment(checkedValues);
  };

  const collectBoardWired = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Board")
        .map((wires) => wires.product.attributes.board_wired)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesBoardWired = Array.from([
    ...new Set(collectBoardWired.flat(Infinity)),
  ]);
  const [selectedBoardWired, setSelectedBoardWired] = useState([]);
  const handleFilterBoardWired = (checkedValues) => {
    setSelectedBoardWired(checkedValues);
  };

  // switch attributes

  const collectSwitchType = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Switch")
        .map((switchTypes) => switchTypes.product.attributes.switch_type)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesSwitchType = Array.from([
    ...new Set(collectSwitchType.flat(Infinity)),
  ]);
  const [selectedSwitchType, setSelectedSwitchType] = useState([]);
  const handleFilterSwitchType = (checkedValues) => {
    setSelectedSwitchType(checkedValues);
  };

  const collectSwitchColor = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Switch")
        .map((switchColors) => switchColors.product.attributes.switch_color)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesSwitchColor = Array.from([
    ...new Set(collectSwitchColor.flat(Infinity)),
  ]);
  const [selectedSwitchColor, setSelectedSwitchColor] = useState([]);
  const handleFilterSwitchColor = (checkedValues) => {
    setSelectedSwitchColor(checkedValues);
  };

  const collectSwitchBrand = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Switch")
        .map((switchBrands) => switchBrands.product.attributes.switch_brand)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesSwitchBrand = Array.from([
    ...new Set(collectSwitchBrand.flat(Infinity)),
  ]);
  const [selectedSwitchBrand, setSelectedSwitchBrand] = useState([]);
  const handleFilterSwitchBrand = (checkedValues) => {
    setSelectedSwitchBrand(checkedValues);
  };

  // keycaps attributes

  const collectKeycapsProfile = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Keycaps")
        .map((profiles) => profiles.product.attributes.keycaps_profile)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesKeycapsProfile = Array.from([
    ...new Set(collectKeycapsProfile.flat(Infinity)),
  ]);
  const [selectedKeycapsProfile, setSelectedKeycapsProfile] = useState([]);
  const handleFilterKeycapsProfile = (checkedValues) => {
    setSelectedKeycapsProfile(checkedValues);
  };

  const collectKeycapsColor = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Keycaps")
        .map((colors) => colors.product.attributes.keycaps_color)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesKeycapsColor = Array.from([
    ...new Set(collectKeycapsColor.flat(Infinity)),
  ]);
  const [selectedKeycapsColor, setSelectedKeycapsColor] = useState([]);
  const handleFilterKeycapsColor = (checkedValues) => {
    setSelectedKeycapsColor(checkedValues);
  };

  const collectKeycapsMaterial = [
    ...new Set(
      modalInfo
        .filter((type) => type.product.info.category === "Keycaps")
        .map((materials) => materials.product.attributes.keycaps_material)
        .filter((value) => !redundantValues.includes(value))
    ),
  ];
  const uniquesKeycapsMaterial = Array.from([
    ...new Set(collectKeycapsMaterial.flat(Infinity)),
  ]);
  const [selectedKeycapsMaterial, setSelectedKeycapsMaterial] = useState([]);
  const handleFilterKeycapsMaterial = (checkedValues) => {
    setSelectedKeycapsMaterial(checkedValues);
  };

  // 2 - Specific Filter Logic

  // 3 - Filter checks

  // general info checks
  // const priceMatchArray = modalInfo.filter(type => type.product.info.price >= priceFilterValues[0] && type.product.info.price <= priceFilterValues[1]);
  // const ratingMatchArray = priceMatchArray.filter(type => selectedRatings.length === 0 || selectedRatings.includes(type.product.info.rating));
  // // -- attribute checks Pre
  // const preSizeMatchArray = ratingMatchArray.filter(type => selectedPreSizes.length === 0 || selectedPreSizes.includes(type.product.attributes.prebuilt_size));
  // const preLedMatchArray = preSizeMatchArray.filter(type => selectedPreLed.length === 0 || selectedPreLed.includes(type.product.attributes.prebuilt_led));
  // const preSwitchSupportMatchArray = preLedMatchArray.filter(type => selectedPreSwitchSupport.length === 0 || selectedPreSwitchSupport.includes(type.product.attributes.prebuilt_switch_support));
  // const preBrandMatchArray = preSwitchSupportMatchArray.filter(type => selectedPreBrand.length === 0 || selectedPreBrand.includes(type.product.attributes.prebuilt_brand));
  // const preColorMatchArray = preBrandMatchArray.filter(type => selectedPreColor.length === 0 || selectedPreColor.includes(type.product.attributes.prebuilt_colors_available));
  // const preLayoutMatchArray = preColorMatchArray.filter(type => selectedPreLayout.length === 0 || selectedPreLayout.includes(type.product.attributes.prebuilt_layout));
  // const preWiredMatchArray = preLayoutMatchArray.filter(type => selectedPreWired.length === 0 || selectedPreWired.includes(type.product.attributes.prebuilt_wired));

  // // -- attribute checks Custom Board
  // const boardSizeMatchArray = preWiredMatchArray.filter(type => selectedSizes.length === 0 || selectedSizes.includes(type.product.attributes.board_size));
  // const boardBrandMatchArray = boardSizeMatchArray.filter(type => selectedBoardBrand.length === 0 || selectedBoardBrand.includes(type.product.attributes.board_brand));
  // const boardBluetoothMatchArray = boardBrandMatchArray.filter(type => selectedBoardBluetooth.length === 0 || selectedBoardBluetooth.includes(type.product.attributes.board_bluetooth));
  // const boardCableAttachmentMatchArray = boardBluetoothMatchArray.filter(type => selectedBoardCableAttachment.length === 0 || selectedBoardCableAttachment.includes(type.product.attributes.board_cableAttachment));
  // const boardWiredMatchArray = boardCableAttachmentMatchArray.filter(type => selectedBoardWired.length === 0 || selectedBoardWired.includes(type.product.attributes.board_wired));

  // // -- attribute checks Custom Switch
  // const switchTypeMatchArray = boardWiredMatchArray.filter(type => selectedSwitchType.length === 0 || selectedSwitchType.includes(type.product.attributes.switch_type));
  // const switchColorMatchArray = switchTypeMatchArray.filter(type => selectedSwitchColor.length === 0 || selectedSwitchColor.includes(type.product.attributes.switch_color));
  // const switchBrandMatchArray = switchColorMatchArray.filter(type => selectedSwitchBrand.length === 0 || selectedSwitchBrand.includes(type.product.attributes.switch_brand));

  // // -- attribute checks Custom Keycaps
  // const keycapsProfileMatchArray = switchBrandMatchArray.filter(type => selectedKeycapsProfile.length === 0 || selectedKeycapsProfile.includes(type.product.attributes.keycaps_profile));
  // const keycapsColorMatchArray = keycapsProfileMatchArray.filter(type => selectedKeycapsColor.length === 0 || selectedKeycapsColor.includes(type.product.attributes.keycaps_color));
  // const keycapsMaterialMatchArray = keycapsColorMatchArray.filter(type => selectedKeycapsMaterial.length === 0 || selectedKeycapsMaterial.includes(type.product.attributes.keycaps_material));
  // const keycapsAmountMatchArray = keycapsMaterialMatchArray.filter(type => selectedKeycapsAmount.length === 0 || selectedKeycapsAmount.includes(type.product.attributes.keycaps_amount));

  // const categoryMatchArray = keycapsAmountMatchArray.filter(type => type.product.info.category === filterComponent);

  // General filter checks
  const priceMatchArray = modalInfo.filter(
    (type) =>
      type.product.info.price >= priceFilterValues[0] &&
      type.product.info.price <= priceFilterValues[1]
  );
  const ratingMatchArray = priceMatchArray.filter(
    (type) =>
      selectedRatings.length === 0 ||
      selectedRatings.includes(type.product.info.rating)
  );

  // Pre-built filter checks
  const preSizeMatchArray = ratingMatchArray.filter(
    (type) =>
      selectedPreSizes.length === 0 ||
      selectedPreSizes.some((option) =>
        option.includes(type.product.attributes.prebuilt_size)
      )
  );
  const preLedMatchArray = preSizeMatchArray.filter(
    (type) =>
      selectedPreLed.length === 0 ||
      selectedPreLed.some((option) =>
        option.includes(type.product.attributes.prebuilt_led)
      )
  );
  const preSwitchSupportMatchArray = preLedMatchArray.filter(
    (type) =>
      selectedPreSwitchSupport.length === 0 ||
      selectedPreSwitchSupport.some((option) =>
        option.includes(type.product.attributes.prebuilt_switch_support)
      )
  );
  const preBrandMatchArray = preSwitchSupportMatchArray.filter(
    (type) =>
      selectedPreBrand.length === 0 ||
      selectedPreBrand.some((option) =>
        option.includes(type.product.attributes.prebuilt_brand)
      )
  );
  const preColorMatchArray = preBrandMatchArray.filter(
    (type) =>
      selectedPreColor.length === 0 ||
      selectedPreColor.some((option) =>
        option.includes(type.product.attributes.prebuilt_colors_available)
      )
  );
  const preLayoutMatchArray = preColorMatchArray.filter(
    (type) =>
      selectedPreLayout.length === 0 ||
      selectedPreLayout.some((option) =>
        option.includes(type.product.attributes.prebuilt_layout)
      )
  );
  const preWiredMatchArray = preLayoutMatchArray.filter(
    (type) =>
      selectedPreWired.length === 0 ||
      selectedPreWired.some((option) =>
        option.includes(type.product.attributes.prebuilt_wired)
      )
  );

  // Custom > Boards filter checks
  const boardSizeMatchArray = preWiredMatchArray.filter(
    (type) =>
      selectedSizes.length === 0 ||
      selectedSizes.some((option) =>
        option.includes(type.product.attributes.board_size)
      )
  );
  const boardBrandMatchArray = boardSizeMatchArray.filter(
    (type) =>
      selectedBoardBrand.length === 0 ||
      selectedBoardBrand.some((option) =>
        option.includes(type.product.attributes.board_brand)
      )
  );
  const boardBluetoothMatchArray = boardBrandMatchArray.filter(
    (type) =>
      selectedBoardBluetooth.length === 0 ||
      selectedBoardBluetooth.some((option) =>
        option.includes(type.product.attributes.board_bluetooth)
      )
  );
  const boardCableAttachmentMatchArray = boardBluetoothMatchArray.filter(
    (type) =>
      selectedBoardCableAttachment.length === 0 ||
      selectedBoardCableAttachment.some((option) =>
        option.includes(type.product.attributes.board_cableAttachment)
      )
  );
  const boardWiredMatchArray = boardCableAttachmentMatchArray.filter(
    (type) =>
      selectedBoardWired.length === 0 ||
      selectedBoardWired.some((option) =>
        option.includes(type.product.attributes.board_wired)
      )
  );

  // Custom > Switch filter checks
  const switchTypeMatchArray = boardWiredMatchArray.filter(
    (type) =>
      selectedSwitchType.length === 0 ||
      selectedSwitchType.some((option) =>
        option.includes(type.product.attributes.switch_type)
      )
  );
  const switchColorMatchArray = switchTypeMatchArray.filter(
    (type) =>
      selectedSwitchColor.length === 0 ||
      selectedSwitchColor.some((option) =>
        option.includes(type.product.attributes.switch_color)
      )
  );
  const switchBrandMatchArray = switchColorMatchArray.filter(
    (type) =>
      selectedSwitchBrand.length === 0 ||
      selectedSwitchBrand.some((option) =>
        option.includes(type.product.attributes.switch_brand)
      )
  );

  // Custom > Keycaps filter checks
  const keycapsProfileMatchArray = switchBrandMatchArray.filter(
    (type) =>
      selectedKeycapsProfile.length === 0 ||
      selectedKeycapsProfile.some((option) =>
        option.includes(type.product.attributes.keycaps_profile)
      )
  );
  const keycapsColorMatchArray = keycapsProfileMatchArray.filter(
    (type) =>
      selectedKeycapsColor.length === 0 ||
      selectedKeycapsColor.some((option) =>
        option.includes(type.product.attributes.keycaps_color)
      )
  );
  const keycapsMaterialMatchArray = keycapsColorMatchArray.filter(
    (type) =>
      selectedKeycapsMaterial.length === 0 ||
      selectedKeycapsMaterial.some((option) =>
        option.includes(type.product.attributes.keycaps_material)
      )
  );

  // Final filter check!
  const matchArray = keycapsMaterialMatchArray.filter(
    (type) => type.product.info.category === filterComponent
  );

  const filterItems = [
    {
      key: "1",
      label: "Filters",
      children: (
        <div className="modal-builder-filterbar">
          <div className="filter-prize">
            <h6>Price</h6>
            <p>
              {priceFilterValues[0]} - {priceFilterValues[1]}
            </p>
            <Slider
              range
              max={100}
              defaultValue={[0, 100]}
              onChange={setPriceFilterValues}
            />
          </div>
          <div className="filter-rating">
            <h6>Rating</h6>
            <Checkbox.Group
              onChange={handleFilterRating}
              options={fixedRatings}
              value={selectedRatings}
            />
          </div>
          {props.componentType === "Pre-built" && (
            <>
              <div className="filter-size">
                <h6>Size</h6>
                <Checkbox.Group
                  onChange={handleFilterPreSize}
                  options={uniquesPreSizes}
                />
              </div>
              <div className="filter-led-support">
                <h6>LED support</h6>
                <Checkbox.Group
                  onChange={handleFilterPreLed}
                  options={uniquesPreLed}
                />
              </div>
              <div className="filter-switch-support">
                <h6>Switch Support</h6>
                <Checkbox.Group
                  onChange={handleFilterPreSwitchSupport}
                  options={uniquesPreSwitchSupport}
                />
              </div>
              <div className="filter-brand">
                <h6>Brand</h6>
                <Checkbox.Group
                  onChange={handleFilterPreBrand}
                  options={uniquesPreBrand}
                />
              </div>
              <div className="filter-colors">
                <h6>Colors</h6>
                <Checkbox.Group
                  onChange={handleFilterPreColor}
                  options={uniquesPreColors}
                />
              </div>
              <div className="filter-layout">
                <h6>Layout</h6>
                <Checkbox.Group
                  onChange={handleFilterPreLayout}
                  options={uniquesPreLayout}
                />
              </div>
              <div className="filter-wire">
                <h6>Wire</h6>
                <Checkbox.Group
                  onChange={handleFilterPreWired}
                  options={uniquesPreWired}
                />
              </div>
            </>
          )}
          {props.componentType === "Board" && (
            <>
              <div className="filter-size">
                <h6>Size</h6>
                <Checkbox.Group
                  onChange={handleFilterSize}
                  options={uniquesSizes}
                />
              </div>
              <div className="filter-brand">
                <h6>Brand</h6>
                <Checkbox.Group
                  onChange={handleFilterBoardBrand}
                  options={uniquesBoardBrand}
                />
              </div>
              <div className="filter-bluetooth">
                <h6>Bluetooth connectivity</h6>
                <Checkbox.Group
                  onChange={handleFilterBoardBluetooth}
                  options={uniquesBoardBluetooth}
                />
              </div>
              <div className="filter-cable">
                <h6>Cable attached</h6>
                <Checkbox.Group
                  onChange={handleFilterBoardCableAttachment}
                  options={uniquesBoardCableAttachment}
                />
              </div>
              <div className="filter-wire">
                <h6>Wire</h6>
                <Checkbox.Group
                  onChange={handleFilterBoardWired}
                  options={uniquesBoardWired}
                />
              </div>
            </>
          )}
          {props.componentType === "Switch" && (
            <>
              <div className="filter-switch-type">
                <h6>Switch type</h6>
                <Checkbox.Group
                  onChange={handleFilterSwitchType}
                  options={uniquesSwitchType}
                />
              </div>
              <div className="filter-switch-color">
                <h6>Switch Color</h6>
                <Checkbox.Group
                  onChange={handleFilterSwitchColor}
                  options={uniquesSwitchColor}
                />
              </div>
              <div className="filter-switch-brand">
                <h6>Switch Brand</h6>
                <Checkbox.Group
                  onChange={handleFilterSwitchBrand}
                  options={uniquesSwitchBrand}
                />
              </div>
            </>
          )}
          {props.componentType === "Keycaps" && (
            <>
              <div className="filter-profile">
                <h6>Profile</h6>
                <Checkbox.Group
                  onChange={handleFilterKeycapsProfile}
                  options={uniquesKeycapsProfile}
                />
              </div>
              <div className="filter-color">
                <h6>Color</h6>
                <Checkbox.Group
                  onChange={handleFilterKeycapsColor}
                  options={uniquesKeycapsColor}
                />
              </div>
              <div className="filter-material">
                <h6>Material</h6>
                <Checkbox.Group
                  onChange={handleFilterKeycapsMaterial}
                  options={uniquesKeycapsMaterial}
                />
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  // dyanmic width
  const [browserWidth, setBrowserWidth] = useState(["1"]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setBrowserWidth(["2"]);
      } else {
        setBrowserWidth(["1"]);
      }
    };

    // Set the initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <>
      {props.componentFilled === false && (
        <tr className="componentFilled-false">
          <td colSpan="8">
            <div className="add-component">
              <div className="empty">
                <h4>{props.componentType}</h4>
                <Button
                  onClick={() => {
                    if (props.componentType != null) {
                      setFilterComponent(props.componentType);
                    }
                    showModal();
                  }}
                  type="ghost"
                  shape="round"
                  icon={<PlusOutlined />}
                >
                  Add {props.componentType}
                </Button>
              </div>
            </div>
          </td>
        </tr>
      )}
      {props.componentFilled === true && (
        <tr className="componentFilled-true">
          <td
            style={{ ...tdFilledStyle, ...tdFilledFirst }}
            className="component-type-title"
          >
            <h4
              style={{
                color: "#181818",
              }}
              className="component-type"
            >
              {props.componentType}
            </h4>
          </td>
          <td style={tdFilledStyle} className="component-title">
            {props.title}
          </td>
          <td style={tdFilledStyle}>
            <img
              style={{
                height: "64px",
                width: "auto",
                borderRadius: "4px",
              }}
              src={props.img}
              alt={props.title}
            />
          </td>
          <td style={tdFilledStyle}>${props.originalPrice}</td>
          <td style={tdFilledStyle}>{props.discount}%</td>
          <td style={tdFilledStyle}>${props.price}</td>
          <td style={tdFilledStyle}>
            <Button
              className="buy-external"
              type="default"
              shape="round"
              href={props.buyLink}
              target="_blank"
              icon={<AliIcon />}
            >
              Buy
            </Button>
          </td>
          <td style={{ ...tdFilledLast, ...tdFilledStyle }}>
            <div className="change-component">
              <Button
                shape="circle"
                className="editButton"
                icon={<EditFilled />}
                onClick={() => {
                  if (props.componentType != null) {
                    setFilterComponent(props.componentType);
                  }
                  showModal();
                }}
              >
                <span className="edit-button-text">Edit</span>
              </Button>
              <Button
                shape="circle"
                className="deleteButton"
                danger={true}
                icon={<DeleteFilled />}
                onClick={() => {
                  if (builderTypeAtomRead === "pre") {
                    if (props.componentType === "Pre-built") {
                      resetPrePreBuilt();
                    } else if (props.componentType === "Keycaps") {
                      resetPreKeycaps();
                    } else if (props.componentType === "Lubrication") {
                      resetPreLubrication();
                    } else if (props.componentType === "Stabilizers") {
                      resetPreStabilizers();
                    } else if (props.componentType === "O-Rings") {
                      resetPreOrings();
                    } else if (props.componentType === "Switch Films") {
                      resetPreSwitchFilms();
                    } else if (props.componentType === "Cable") {
                      resetPreCable();
                    } else if (props.componentType === "Deskmats") {
                      resetPreDeskmats();
                    } else if (props.componentType === "Wrist rests") {
                      resetPreWristRests();
                    }
                  } else if (builderTypeAtomRead === "custom") {
                    if (props.componentType === "Board") {
                      resetCustomBoard();
                    } else if (props.componentType === "Switch") {
                      resetCustomSwitches();
                    } else if (props.componentType === "Keycaps") {
                      resetCustomKeycaps();
                    } else if (props.componentType === "Lubrication") {
                      resetCustomLubrication();
                    } else if (props.componentType === "Stabilizers") {
                      resetCustomStabilizers();
                    } else if (props.componentType === "O-Rings") {
                      resetCustomOrings();
                    } else if (props.componentType === "Switch Films") {
                      resetCustomSwitchFilms();
                    } else if (props.componentType === "Cable") {
                      resetCustomCable();
                    } else if (props.componentType === "Switch Tester") {
                      resetCustomSwitchTester();
                    } else if (props.componentType === "Keycap Pullers") {
                      resetCustomKeycapPuller();
                    } else if (props.componentType === "Switch Opener") {
                      resetCustomSwitchOpener();
                    } else if (props.componentType === "Switch Holder") {
                      resetCustomSwitchHolder();
                    } else if (props.componentType === "Deskmats") {
                      resetCustomDeskmats();
                    } else if (props.componentType === "Wrist rests") {
                      resetCustomWristRests();
                    }
                  }
                  notificationRemoved("error");
                  handleCancel();
                }}
              >
                <span className="delete-button-text">Delete</span>
              </Button>
            </div>
          </td>
        </tr>
      )}
      <Modal
        className="builderModal"
        open={modalVisibility}
        footer={null}
        onCancel={handleCancel}
        title={
          modalDetailInfo === "" ? (
            `Add ${props.componentType}`
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                shape="round"
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={resetModalDetailInfo}
                style={{
                  position: "absolute",
                }}
              >
                Back to overview
              </Button>
              <h5
                style={{
                  color: "#181818",
                  margin: "0 auto",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "60%",
                }}
              >
                About {modalDetailInfo}
              </h5>
            </div>
          )
        }
        width="80vw"
      >
        {modalDetailInfo === "" ? (
          <div className="modal-builder-container">
            <div className="modal-builder-toggle">
              <Switch
                checkedChildren="Compatability filter"
                unCheckedChildren="Comptability filter"
                defaultChecked
              />
            </div>
            <div className="modal-builder-search">
              <Input
                placeholder={`Search for ${props.componentType}`}
                prefix={<SearchOutlined />}
              />
            </div>
            {/*  filters dynamic  */}
            <div>
              <Collapse
                defaultActiveKey={browserWidth}
                items={filterItems}
                className="accordionFilter"
              />
            </div>
            <div className="modal-builder-content">
              {readProductLoader && (
                <Spin
                  size="large"
                  tip="Loading products..."
                  style={{
                    position: "relative",
                    left: "100%",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
              {!readProductLoader &&
                matchArray.map((card, cardIndex) => {
                  return (
                    <ModalBuilderCard
                      key={cardIndex}
                      title={card.product.info.title}
                      price={card.product.info.price}
                      originalPrice={card.product.info.original_price}
                      discount={card.product.info.discount.replace("%", "")}
                      img={card.product.info.images}
                      rating={card.product.info.rating}
                      buyLink={card.product.info.promotion_link}
                      category={card.product.info.category}
                      latestVolume={card.product.info.latest_volume}
                      componentType={props.componentType}
                      parentHandleCancel={handleCancel}
                    />
                  );
                })}
              {!readProductLoader && matchArray.length === 0 && (
                <Result
                  className="item-not-found"
                  status="404"
                  title="No parts found"
                  subTitle="Try adjusting your filter settings to show results"
                />
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="component-detailer-scrollcontainer">
              <div className="component-img" style={mainImageStyle}></div>
              <div className="component-detailer-metadata">
                <div>
                  <h1
                    style={{
                      color: "black",
                    }}
                  >
                    {modalDetailInfoComponent.info.name}
                  </h1>
                  <div className="sub-metadata">
                    <Rate
                      disabled
                      defaultValue={modalDetailInfoComponent.info.rating}
                    />
                    <p>
                      {modalDetailInfoComponent.info.rating} (
                      {modalDetailInfoComponent.info.ratersAmount})
                    </p>
                    <span>|</span>
                    <p>
                      {modalDetailInfoComponent.info.latestVolume} left in stock
                    </p>
                  </div>
                  <div className="preview-images-container">
                    {modalDetailInfoComponent.info.img.map(
                      (imageUrl, index) => {
                        return (
                          <Image
                            height="56px"
                            width="72px"
                            key={index}
                            src={imageUrl}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
                <div>
                  {modalDetailInfoComponent.info.price ===
                  modalDetailInfoComponent.info.original_price ? (
                    <span className="modal-pricing">
                      ${modalDetailInfoComponent.info.price}
                    </span>
                  ) : (
                    <span className="modal-pricing">
                      ${modalDetailInfoComponent.info.price}
                      <span>
                        ${modalDetailInfoComponent.info.original_price}
                      </span>
                      {modalDetailInfoComponent.info.discount != 0 ? (
                        <span>
                          {modalDetailInfoComponent.info.discount}% discount
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </span>
                  )}
                  <Button
                    shape="round"
                    type="default"
                    onClick={() => {
                      addToBuilder();
                    }}
                  >
                    Add to builder
                  </Button>
                </div>
              </div>
              <div className="component-detailer-container">
                <div className="component-detailer-description">
                  <h5>Description</h5>
                  <p>{modalDetailInfoComponent.info.description}</p>
                </div>
                <div className="component-detailer-specifications">
                  <h5>Specifications</h5>
                  <Descriptions column={1} bordered>
                    <Descriptions.Item label="Category">
                      {modalDetailInfoComponent.info.category}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Brand">
                      {modalDetailInfoComponent.attributes.board_brand}
                    </Descriptions.Item>
                    <Descriptions.Item label="Size">
                      Random info I get from object in learnMoreCta
                    </Descriptions.Item>
                    <Descriptions.Item label="Colors">
                      Random info I get from object in learnMoreCta
                    </Descriptions.Item>
                    <Descriptions.Item label="Switches">
                      Random info I get from object in learnMoreCta
                    </Descriptions.Item>
                    <Descriptions.Item label="LED">
                      Random info I get from object in learnMoreCta
                    </Descriptions.Item>
                    <Descriptions.Item label="Layout">
                      Random info I get from object in learnMoreCta
                    </Descriptions.Item> */}
                  </Descriptions>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
