import React, { useEffect } from "react";
import "./ModalBuilderCardStyles.css";

import { Button, notification } from "antd";
import { StarFilled } from "@ant-design/icons";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  preCableAtom,
  preDeskmatsAtom,
  preKeycapsAtom,
  preLubricationAtom,
  preOringsAtom,
  prePreBuiltAtom,
  preStabilizersAtom,
  preSwitchFilmsAtom,
  preWristRestsAtom,
} from "../../../../recoil/atoms/builderPre";
import {
  customBoardAtom,
  customCableAtom,
  customDeskmatsAtom,
  customKeycapPullerAtom,
  customKeycapsAtom,
  customLubricationAtom,
  customOringsAtom,
  customStabilizersAtom,
  customSwitchesAtom,
  customSwitchFilmsAtom,
  customSwitchHolderAtom,
  customSwitchOpenerAtom,
  customSwitchTesterAtom,
  customWristRestsAtom,
  learnMoreAtom,
} from "../../../../recoil/atoms/builder";
import {
  learnMoreInfo,
  modalDetailPage,
} from "../../../../recoil/atoms/modalContent";
import { RetrieveProductInfo } from "../../../../helperFunctions/addToBuilder";
import { initBuilderAtom } from "../../../../recoil/atoms/intializeBuilder";

export default function ModalBuilderCard(props) {
  const builderTypeAtomRead = useRecoilValue(initBuilderAtom);

  // useEffect(() => {
  //   console.log(builderTypeAtomRead);
  // }, []);

  const builderCardStyles = {
    backgroundImage: `url(${props.img})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  };

  // add to builder logic

  //pre
  const [prePreBuilt, setPrePreBuilt] = useRecoilState(prePreBuiltAtom);
  const [preKeycaps, setPreKeycaps] = useRecoilState(preKeycapsAtom);
  const [preLubrication, setPreLubrication] =
    useRecoilState(preLubricationAtom);
  const [preStabilizers, setPreStabilizers] =
    useRecoilState(preStabilizersAtom);
  const [preOrings, setPreOrings] = useRecoilState(preOringsAtom);
  const [preSwitchFilms, setPreSwitchFilms] =
    useRecoilState(preSwitchFilmsAtom);
  const [preCable, setPreCable] = useRecoilState(preCableAtom);
  const [preDeskmats, setPreDeskmats] = useRecoilState(preDeskmatsAtom);
  const [preWristRests, setPreWristRests] = useRecoilState(preWristRestsAtom);

  // custom
  const [customBoard, setCustomBoard] = useRecoilState(customBoardAtom);
  const [customSwitches, setCustomSwitches] =
    useRecoilState(customSwitchesAtom);
  const [customKeycaps, setCustomKeycaps] = useRecoilState(customKeycapsAtom);
  const [customLubrication, setCustomLubrication] = useRecoilState(
    customLubricationAtom
  );
  const [customStabilizers, setCustomStabilizers] = useRecoilState(
    customStabilizersAtom
  );
  const [customOrings, setCustomOrings] = useRecoilState(customOringsAtom);
  const [customSwitchFilms, setCustomSwitchFilms] = useRecoilState(
    customSwitchFilmsAtom
  );
  const [customCable, setCustomCable] = useRecoilState(customCableAtom);
  const [customSwitchTester, setCustomSwitchTester] = useRecoilState(
    customSwitchTesterAtom
  );
  const [customKeycapPuller, setCustomKeycapPuller] = useRecoilState(
    customKeycapPullerAtom
  );
  const [customSwitchOpener, setCustomSwitchOpener] = useRecoilState(
    customSwitchOpenerAtom
  );
  const [customSwitchHolder, setCustomSwitchHolder] = useRecoilState(
    customSwitchHolderAtom
  );
  const [customDeskmats, setCustomDeskmats] =
    useRecoilState(customDeskmatsAtom);
  const [customWristRests, setCustomWristRests] =
    useRecoilState(customWristRestsAtom);

  const notificationSuccess = (type) => {
    notification[type]({
      message: "Success!",
      description: `${props.componentType} added to your build`,
    });
  };

  function addToBuilder() {
    const productObject = RetrieveProductInfo(props);
    //   console.log("It works: ", JSON.stringify(productObject));

    if (builderTypeAtomRead === "pre") {
      if (productObject.category === "Pre-built") {
        setPrePreBuilt(productObject);
      } else if (productObject.category === "Keycaps") {
        setPreKeycaps(productObject);
      } else if (productObject.category === "Lubrication") {
        setPreLubrication(productObject);
      } else if (productObject.category === "Stabilizers") {
        setPreStabilizers(productObject);
      } else if (productObject.category === "O-Rings") {
        setPreOrings(productObject);
      } else if (productObject.category === "Switch Films") {
        setPreSwitchFilms(productObject);
      } else if (productObject.category === "Cable") {
        setPreCable(productObject);
      } else if (productObject.category === "Deskmats") {
        setPreDeskmats(productObject);
      } else if (productObject.category === "Wrist rests") {
        setPreWristRests(productObject);
      }
    } else if (builderTypeAtomRead === "custom") {
      if (productObject.category === "Board") {
        setCustomBoard(productObject);
      } else if (productObject.category === "Switch") {
        setCustomSwitches(productObject);
      } else if (productObject.category === "Keycaps") {
        setCustomKeycaps(productObject);
      } else if (productObject.category === "Lubrication") {
        setCustomLubrication(productObject);
      } else if (productObject.category === "Stabilizers") {
        setCustomStabilizers(productObject);
      } else if (productObject.category === "O-Rings") {
        setCustomOrings(productObject);
      } else if (productObject.category === "Switch Films") {
        setCustomSwitchFilms(productObject);
      } else if (productObject.category === "Cable") {
        setCustomCable(productObject);
      } else if (productObject.category === "Switch Tester") {
        setCustomSwitchTester(productObject);
      } else if (productObject.category === "Keycap Pullers") {
        setCustomKeycapPuller(productObject);
      } else if (productObject.category === "Switch Opener") {
        setCustomSwitchOpener(productObject);
      } else if (productObject.category === "Switch Holder") {
        setCustomSwitchHolder(productObject);
      } else if (productObject.category === "Deskmats") {
        setCustomDeskmats(productObject);
      } else if (productObject.category === "Wrist rests") {
        setCustomWristRests(productObject);
      }
    }
    notificationSuccess("success");
    props.parentHandleCancel();
  }

  // logic to show modal detail info of a single component

  const [learnMore, setLearnMore] = useRecoilState(learnMoreInfo);
  const setDetailComponent = useSetRecoilState(modalDetailPage);

  function learnMoreCta() {
    setLearnMore((prevState) => {
      // Ensure img is an array
      const imgArray = Array.isArray(props.img)
        ? props.img
        : [props.img].filter(Boolean);

      return {
        ...prevState,
        info: {
          ...prevState.info,
          name: props.title,
          price: props.price,
          original_price: props.originalPrice,
          discount: props.discount,
          img: imgArray, // Use the ensured array here
          buyLink: props.buyLink,
          rating: props.rating,
          category: props.category,
          latestVolume: props.latestVolume,
          details: props.details,
          description: props.description,
        },
      };
    });
    console.log(learnMore);
  }

  const [readingLearnMore, setReadingLearnMore] = useRecoilState(learnMoreAtom);

  function saveLearnMoreAtom() {
    const learnMoreObject = RetrieveProductInfo(props);
    setReadingLearnMore(learnMoreObject);
    console.log("Learn more state save: ", readingLearnMore);
  }

  return (
    <div className="modal-builder-card">
      <div
        className="modal-builder-card-img"
        style={builderCardStyles}
        onClick={() => {
          saveLearnMoreAtom();
          setDetailComponent(props.title);
          learnMoreCta();
        }}
      ></div>
      <div className="productInfo">
        <div>
          {/* Rating komt later, voor nu even weglaten */}
          {/* <div className="modal-component-rating">
            <StarFilled />
            <span>{props.rating}</span>
          </div> */}
          <a
            onClick={() => {
              saveLearnMoreAtom();
              setDetailComponent(props.title);
              learnMoreCta();
            }}
          >
            <h6 style={{ color: "black" }}>{props.title}</h6>
          </a>
          <span className="price">${props.price}</span>
          {props.discount === "0" ? null : (
            <span className="discount">${props.originalPrice}</span>
          )}
        </div>
        <Button
          className="hoverButton"
          shape="round"
          type="default"
          onClick={() => {
            addToBuilder();
          }}
        >{`Add ${props.componentType}`}</Button>
        {/* <Button
                    shape='round'
                    style={{
                        marginTop: '12px'
                    }}
                    onClick={() => {
                        saveLearnMoreAtom()
                        setDetailComponent(props.title);
                        learnMoreCta();
                    }}
                >
                    Learn more
                </Button> */}
      </div>
    </div>
  );
}
